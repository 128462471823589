<template>
  <div class='f-footer' style='' :class='{"bg-white":$root.d,"bg-dark":!$root.d}'>
      <img src="@/assets/Claimelly copy 6.png" alt="" class="d-block w-100">
  </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style>

</style>